// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import Modal from 'bootstrap/js/dist/modal';

Rails.start()
ActiveStorage.start()

import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/tab"
import "bootstrap/js/dist/collapse"
import 'bootstrap-icons/font/bootstrap-icons.css'
import Tooltip from "bootstrap/js/dist/tooltip"
import Collapse from "bootstrap/js/dist/collapse"
import "../stylesheets/application.scss"

const images = require.context('../images', true)

function scrollFunction(el) {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    el.style.display = "block";
  } else {
    el.style.display = "none";
  }
}

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

jQuery(function () {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

  //const collapseElementList = document.querySelectorAll('.collapse')
  //const collapseList = [...collapseElementList].map(collapseEl => new Collapse(collapseEl))

  // get the "back to top" button
  const top_top_button = document.getElementById("btn-back-to-top");
  if (top_top_button) {
    // when the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction(top_top_button);
    };

    // when the user clicks on the button, scroll to the top of the document
    top_top_button.addEventListener("click", backToTop);
  }


  // oh this is bad... this is so so bad...
  // f..king evil hack... I should not be forced to do this...
  //
  // source of problem: https://github.com/twbs/bootstrap/issues/36681
  // when clicking on anchor links in offcanvas elements, chrome stops scrolling
  // after offcanvas' transition to hidden has completed.
  //
  // what we can do about it? wait until offcanvas is hidden and start action after
  // that. how to do it? hack into events from offcanvas, and on event "hidden"
  // set location of window to the link's URL (anchor in this case, but works with
  // regular URLs as well). but we have to take care, that this action is not
  // performed with the offcanvas closing every time.
  var offcanvasHiddenAction = null;
  const offcanvasMenu = document.getElementById("offcanvas-menu");
  // event listener for offcanvas' event "hidden", simply executes registered action
  offcanvasMenu.addEventListener("hidden.bs.offcanvas", ()=>{
    if (offcanvasHiddenAction) {
      window.location = offcanvasHiddenAction;
      setTimeout(()=>{
        // reset action on "hidden"-event. a little delay is required, or
        // else this will be interrupted by the end of animation again,
        // so all this would be in vain.
        offcanvasHiddenAction = null;
      }, 500);
    }
  });
  // event listener on the offcanvas' dismissing link.
  jQuery("#offcanvas-menu *[data-bs-dismiss] a").on("click", function(event) {
    // we're setting window.location manually, so don't let the link do it
    event.preventDefault();
    offcanvasHiddenAction = this.getAttribute("href");
  });


  // check if demo user modal should be shown
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has('demo_user') && urlParams.get('demo_user') === 'true') {
    const tryFreeModal = document.getElementById('tryFreeModal');
    if (tryFreeModal) {
      const modal = new Modal(tryFreeModal);
      modal.show();
    }
  }

});
